import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Box, 
  Typography
} from '@mui/material';

const parentesco = [
    { id: 1, parentesco: 'Madre/Padre' },
    { id: 2, parentesco: 'Hermano/Hermana' },
    { id: 3, parentesco: 'Esposo/Esposa' },
    { id: 4, parentesco: 'Hijo/Hija' },
    { id: 5, parentesco: 'Abuelo/Abuela' },
    { id: 6, parentesco: 'Sobrino/Sobrina' },
    { id: 7, parentesco: 'Tío/Tía' },
    { id: 8, parentesco: 'Otro' },
];

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
}

const parentescoMap = {};
parentesco.forEach((item) => {
    parentescoMap[item.id] = item.parentesco;
});

export const TablasContratos = ({ tipoServicio, detalles, cliente, difunto }) => {  
  const renderTable = () => {  
      if (!detalles || detalles.length === 0) {  
          return <p>No hay detalles disponibles.</p>;  
      }  

    switch (tipoServicio) {  
        case 'Previsivo':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '40px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* Tabla de Familiares */}
                            {cliente.familiares && cliente.familiares.length > 0 && (  
                                <Box sx={{ marginTop: '20px' }}>
                                    <Typography 
                                        sx={{ marginBottom: '10px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                        variant="h6" 
                                        gutterBottom
                                    >
                                        FAMILIARES
                                    </Typography>
                                    <TableContainer component={Box}>
                                        <Table>
                                            <TableHead>
                                                <TableRow sx={{ backgroundColor: '#0A4575' }}>  
                                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Nombre y Apellido</TableCell>  
                                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Cédula de Identidad</TableCell>    
                                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Edad</TableCell>
                                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Parentesco</TableCell>  
                                                </TableRow>  
                                            </TableHead>  
                                            <TableBody>  
                                                {cliente.familiares.map((familiar) => (
                                                    <TableRow key={familiar.id}>  
                                                        <TableCell sx={{ textAlign: 'center'}}>{familiar.nombre}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center'}}>{familiar.cedula}</TableCell>    
                                                        <TableCell sx={{ textAlign: 'center'}}>{familiar.edad}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center'}}>{parentescoMap[familiar.parentesco]}</TableCell>  
                                                    </TableRow>  
                                                ))}  
                                            </TableBody>  
                                        </Table>  
                                    </TableContainer>  
                                </Box>
                            )}                        
                        </Box>
                    )}
                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Tipo de Contrato</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Plan</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Modalidad de Pago</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.tipo_contrato}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.modalidadPago}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>
                </>  
            );  

        case 'Servicio Funerario':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '20px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                   
                        </Box>
                    )}

                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>                                            
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Traslado</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Tiempo de Velación</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Velación</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Preparación</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Alquiler Ataud</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>                                                
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.traslado || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.tiempo_velacion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio_velacion || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.incluye_preparacion || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.incluye_ataud || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>

                    {/* DATOS DEL DIFUNTO */}
                    {difunto && difunto.length > 0 && (
                        <Box sx={{ marginBottom: '40px' }}>
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL DIFUNTO
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Fecha de Nacimiento</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Edad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Parentesco</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Fecha de Defunción</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Causa de Defunción</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección de Defunción</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {difunto.map((d) => (
                                            <TableRow key={d.id}>
                                                <TableCell sx={{ textAlign: 'center'}}>{d.nombre_difunto}</TableCell>
                                                <TableCell sx={{ textAlign: 'center'}}>{d.cedula_difunto}</TableCell>
                                                <TableCell sx={{ textAlign: 'center'}}>{formatDate(d.fecha_nac_difunto)}</TableCell>
                                                <TableCell sx={{ textAlign: 'center'}}>{d.edad_difunto}</TableCell>
                                                <TableCell sx={{ textAlign: 'center'}}>{parentescoMap[d.parentesco_difunto]}</TableCell>
                                                <TableCell sx={{ textAlign: 'center'}}>{formatDate(d.fecha_defuncion)}</TableCell>
                                                <TableCell sx={{ textAlign: 'center'}}>{d.causa_defuncion}</TableCell>
                                                <TableCell sx={{ textAlign: 'center'}}>{d.direccion_defuncion}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                   
                        </Box>
                    )}                    
                </>  
        );            
        // Agrega casos para 'Servicio Funerario' y otros tipos de servicio si es necesario
        case 'parcelas':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '40px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                  
                        </Box>
                    )}
                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>                                          
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Plan</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Modalidad de Pago</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>                                              
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.modalidadPago}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>
                </>  
            ); 

        case 'nichos-columbarios':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '40px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                  
                        </Box>
                    )}
                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>                                          
                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Código</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Plan</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Modalidad de Pago</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Pago Inicial</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Monto Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>                                              
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.codigo_nicho}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.modalidadPago}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.pagoInicial || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.cuotas}</TableCell> 
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.montoPorCuota || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>
                </>  
            ); 

        case 'nichos-ataud':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '40px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                  
                        </Box>
                    )}
                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>                                          
                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Código</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Plan</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Modalidad de Pago</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Pago Inicial</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Monto Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>                                              
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.codigo_nicho}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.modalidadPago}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.pagoInicial || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.cuotas}</TableCell> 
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.montoPorCuota || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>
                </>  
            );  

        case 'cremacion':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '40px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                  
                        </Box>
                    )}
                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>                                          
                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Código</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Plan</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Modalidad de Pago</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Pago Inicial</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Monto Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>                                              
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.codigo_nicho}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.modalidadPago}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.pagoInicial || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.cuotas}</TableCell> 
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.montoPorCuota || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>
                </>  
            );     

        case 'honra-exequias':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '40px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                  
                        </Box>
                    )}
                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>                                          
                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Código</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Plan</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Modalidad de Pago</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Pago Inicial</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Monto Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>                                              
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.codigo_nicho}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.modalidadPago}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.pagoInicial || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.cuotas}</TableCell> 
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.montoPorCuota || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>
                </>  
            );   

        case 'inhumacion':  
            return (  
                <>  {/* Fragmento de React */}
                    {cliente && (
                        <Box sx={{ marginBottom: '40px' }}>
                            {/* Tabla de Datos del Cliente */}
                            <Typography 
                                sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                                variant="h6" 
                                gutterBottom
                            >
                                DATOS DEL CLIENTE
                            </Typography>                
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Celular</TableCell>
                                            <TableCell sx={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px', color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.nombre}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.cedula}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.direccion}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.celular}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{cliente.email}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>                                                  
                        </Box>
                    )}
                    {/* Tabla de Detalles de Contrato */}
                    <Box>
                        <Typography 
                            sx={{ marginTop: '20px', textAlign: 'left', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} 
                            variant="h6" 
                            gutterBottom
                        >
                            DETALLES DEL CONTRATO
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#0A4575' }}>                                          
                                    <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Código</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Descripción</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Plan</TableCell>  
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Modalidad de Pago</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Pago Inicial</TableCell> 
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Monto Cuotas</TableCell>
                                        <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Precio Total</TableCell>  
                                    </TableRow>  
                                </TableHead>  
                                <TableBody>  
                                    {detalles.map(detalle => (  
                                        <TableRow key={detalle.id}>                                              
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.codigo_nicho}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.descripcion}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precio || 0}`}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.modalidadPago}</TableCell>  
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.pagoInicial || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{detalle.cuotas}</TableCell> 
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.montoPorCuota || 0}`}</TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                        </TableRow>  
                                    ))}  
                                </TableBody>  
                            </Table>  
                        </TableContainer> 
                    </Box>
                </>  
            );                 
        default:  
            return <p>No hay detalles disponibles para este tipo de servicio.</p>;  
    }  
  };  

  return <div>{renderTable()}</div>;
};
