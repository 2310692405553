import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material';

const Parcelas = ({ agregarAlPresupuesto }) => {
  // Lista de planes de Parcela
  const [planes] = useState([
    {
      id: 1,
      tipo_servicio: 'Fosa/Parcela',
      modalidad: 'De Contado',
      precio: 1500,
      precioContado: 1500
    },
    {
      id: 2,
      tipo_servicio: 'Fosa/Parcela',
      modalidad: 'Financiado',
      precio: 1700,
      precioFinanciado: 1700
    },
    {
      id: 3,
      tipo_servicio: 'Fosa/Parcela',
      modalidad: 'Financiado',
      precio: 2000,
      precioFinanciado: 2000
    }
  ]);

  // Plan seleccionado y código de parcela
  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [codigo, setCodigo] = useState({ codigo: '' });

  // Estados para financiamiento
  const [inicial, setInicial] = useState(0);
  const [cuotas, setCuotas] = useState(1);
  const [montoFinanciar, setMontoFinanciar] = useState(0);
  const [montoCuotas, setMontoCuotas] = useState(0);

  // ===== Manejo de selección de plan y código =====
  const handlePlanChange = (e) => {
    const planId = parseInt(e.target.value, 10);
    const plan = planes.find((p) => p.id === planId);
    setPlanSeleccionado(plan);

    // Al cambiar de plan, reiniciar campos
    setInicial(0);
    setCuotas(1);
  };

  const handleCodigoChange = (e) => {
    setCodigo({ ...codigo, codigo: e.target.value });
  };

  // ===== Cálculos automáticos cuando el plan es “Financiado” =====
  useEffect(() => {
    if (!planSeleccionado) {
      setMontoFinanciar(0);
      setMontoCuotas(0);
      return;
    }

    // Si el plan es financiado, calculamos
    if (planSeleccionado.modalidad === 'Financiado') {
      const total = planSeleccionado.precioFinanciado || 0;
      const montoFinanciarCalculado = total - (inicial || 0);
      setMontoFinanciar(montoFinanciarCalculado > 0 ? montoFinanciarCalculado : 0);

      const cuotasValidas = cuotas > 0 ? cuotas : 1;
      const montoCuotaCalculado =
        montoFinanciarCalculado > 0 ? montoFinanciarCalculado / cuotasValidas : 0;
      setMontoCuotas(montoCuotaCalculado);
    } else {
      // De Contado o cualquier otra modalidad que no sea “Financiado”
      setMontoFinanciar(0);
      setMontoCuotas(0);
    }
  }, [planSeleccionado, inicial, cuotas]);

  // ===== Agregar al Contrato =====
  const handleAgregar = () => {
    if (!planSeleccionado) return;

    // Determinamos precio total y modalidad textual
    let precioTotal = 0;
    let modalidadPago = '';

    if (planSeleccionado.modalidad === 'Financiado') {
      precioTotal = planSeleccionado.precioFinanciado || 0;
      modalidadPago = 'Financiado';
    } else if (planSeleccionado.modalidad === 'De Contado') {
      precioTotal = planSeleccionado.precioContado || 0;
      modalidadPago = 'De Contado';
    } else {
      // Si por alguna razón no es ni uno ni otro
      return;
    }

    // Construimos el objeto “item” que se enviará al resumen del contrato
    const item = {
      // Código que introdujo el usuario
      codigo: codigo.codigo,

      // Puedes poner la descripción como gustes; por ejemplo, usando “tipo_servicio”
      descripcion: planSeleccionado.tipo_servicio,

      // Tipo de servicio, si deseas almacenarlo aparte
      tipo_servicio: planSeleccionado.tipo_servicio,

      // Modalidad “Financiado” o “De Contado”
      modalidadPago,
      // Precio final
      precioTotal,

      // Si es financiado, llevamos estos campos
      pagoInicial: modalidadPago === 'Financiado' ? inicial : '',
      cuotas: modalidadPago === 'Financiado' ? cuotas : '',
      montoPorCuota: modalidadPago === 'Financiado' ? montoCuotas : '',

      // Para tenerlos de referencia
      precioFinanciado:
        modalidadPago === 'Financiado'
          ? planSeleccionado.precioFinanciado
          : '',
      precioContado:
        modalidadPago === 'De Contado'
          ? planSeleccionado.precioContado
          : ''
    };

    // Enviamos el item al padre (VentasContrato o similar)
    agregarAlPresupuesto(item);
  };

  return (
    <>
      <Typography
        sx={{
          marginTop: '25px',
          marginBottom: '5px',
          fontFamily: 'Bakbak One, sans-serif',
          color: '#0A4575',
          textAlign: 'center'
        }}
        variant="h5"
      >
        CONTRATO FOSAS O PARCELAS
      </Typography>

      <Box>
        <Typography
          sx={{
            marginTop: '15px',
            marginBottom: '10px',
            fontFamily: 'Bakbak One, sans-serif',
            color: '#0A4575'
          }}
          variant="h6"
        >
          PLAN A CONTRATAR
        </Typography>
      </Box>

      {/* Selección del Plan y Código de Parcela */}
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        {/* Plan */}
        <Grid item xs={3}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel id="planParcela-label">Plan</InputLabel>
            <Select
              labelId="planParcela-label"
              id="planParcela"
              label="Plan"
              value={planSeleccionado ? planSeleccionado.id : ''}
              onChange={handlePlanChange}
              autoComplete="off"
            >
              <MenuItem value="" disabled>
                Selecciona un Plan
              </MenuItem>
              {planes.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.tipo_servicio} - {plan.modalidad} - ${plan.precio}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Código Parcela */}
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Código Parcela"
            variant="outlined"
            size="small"
            value={codigo.codigo}
            onChange={handleCodigoChange}
            sx={{ boxShadow: 3 }}
          />
        </Grid>

        {/* Si el plan es de contado, mostramos su precio y botón aquí mismo */}
        {planSeleccionado && planSeleccionado.modalidad === 'De Contado' && (
          <>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Precio al Contado"
                variant="outlined"
                size="small"
                name="pago_contado"
                value={
                  planSeleccionado.precioContado
                    ? `$${planSeleccionado.precioContado}`
                    : ''
                }
                sx={{ boxShadow: 3 }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAgregar}
                disabled={!planSeleccionado}
                fullWidth
              >
                Agregar al Contrato
              </Button>
            </Grid>
          </>
        )}

        {/* Si el plan es financiado, mostramos precio y monto inicial */}
        {planSeleccionado && planSeleccionado.modalidad === 'Financiado' && (
          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Precio Financiado"
                variant="outlined"
                size="small"
                name="pago_financiado"
                value={
                  planSeleccionado.precioFinanciado
                    ? `$${planSeleccionado.precioFinanciado}`
                    : ''
                }
                sx={{ boxShadow: 3 }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Monto Inicial ($)"
                variant="outlined"
                size="small"
                type="number"
                name="inicial"
                value={inicial}
                onChange={(e) => setInicial(parseFloat(e.target.value) || 0)}
                sx={{ boxShadow: 3 }}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* Segunda línea para financiar: Monto a Financiar, Cuotas, Monto por Cuota y botón */}
      {planSeleccionado && planSeleccionado.modalidad === 'Financiado' && (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Monto a Financiar ($)"
              variant="outlined"
              size="small"
              name="monto_financiar"
              value={`$${montoFinanciar.toFixed(2)}`}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Cuotas"
              variant="outlined"
              size="small"
              name="cuotas"
              value={cuotas}
              onChange={(e) => setCuotas(parseInt(e.target.value) || 1)}
              sx={{ boxShadow: 3 }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Monto por Cuota"
              variant="outlined"
              size="small"
              name="monto_cuotas"
              value={`$${montoCuotas.toFixed(2)}`}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAgregar}
              disabled={!planSeleccionado}
              fullWidth
            >
              Agregar al Contrato
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Parcelas;
