import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import axios from 'axios';
import { TablasContratos } from './TablasContratos';

const ContratoList = () => {
  const [contratos, setContratos] = useState([]);
  const [detallesContrato, setDetallesContrato] = useState([]);  // Asegúrate de que detallesContrato sea un array
  const [openDialog, setOpenDialog] = useState(false);
  const [tipoServicio, setTipoServicio] = useState("");  // Usamos tipoServicio para pasar a TablasContratos
  const [difuntoSeleccionado, setDifunto] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
  
  useEffect(() => {
    const obtenerContratos = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${apiUrl}/api/contratos`, config);
        setContratos(response.data);
      } catch (error) {
        console.error("Error al obtener los contratos:", error);
      }
    };

    obtenerContratos();
  }, [apiUrl]);

  const mostrarDetallesContrato = async (contratoId, tipo_servicio) => {
    try {
      const token = localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${apiUrl}/api/detalles_contrato/${contratoId}`, config);
      setDetallesContrato(response.data);
      setTipoServicio(tipo_servicio); 

      if (tipo_servicio === "Servicio Funerario") {
        try {
            const responseDifunto = await axios.get(`${apiUrl}/api/difunto/${contratoId}`, config);
            setDifunto(responseDifunto.data);
        } catch (error) {
            console.warn("No se encontraron datos del difunto:", error);
            setDifunto(null); // Limpia los datos del difunto si no se encuentran
        }
    } else {
        setDifunto(null); // Limpia los datos del difunto para otros servicios
    }
      
      setOpenDialog(true);
    } catch (error) {
      console.error("Error al obtener los detalles del contrato:", error);
      setDetallesContrato([]);  // Limpiar si no hay detalles
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDetallesContrato([]);  // Limpiar detalles al cerrar el modal
  };

  return (
    <Box component="form" sx={{ maxWidth: '950px', margin: '0 auto', padding: 3, boxShadow: 3, backgroundColor: '#ffffff', borderRadius: 2 }}>
      <Typography sx={{ textAlign: 'center', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6" gutterBottom>
        LISTA DE CONTRATOS
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0A4575' }}>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>No. de Transacción</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Fecha Creación</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Fecha de Vencimiento</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tipo de Servicio</TableCell>
              
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Total</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Estado</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Detalles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contratos.map((contrato) => (
              <TableRow key={contrato.id}>
                <TableCell>{contrato.numero_contrato}</TableCell>
                <TableCell>{formatDate(contrato.fecha_creacion)}</TableCell>
                <TableCell>{contrato.fecha_vencimiento ? formatDate(contrato.fecha_vencimiento) : ''}</TableCell>
                <TableCell>{contrato.tipo_servicio}</TableCell>
                
                <TableCell>{`$${contrato.precio_total}`}</TableCell>
                <TableCell>{contrato.estado}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => mostrarDetallesContrato(contrato.id, contrato.tipo_servicio)}>
                    Ver Detalles
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Diálogo para mostrar los detalles del contrato */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}>
          DETALLES DEL CONTRATO
        </DialogTitle>
        <DialogContent>
          {/* Renderiza la tabla correspondiente según el tipo_servicio */}
          <TablasContratos tipoServicio={tipoServicio} detalles={detallesContrato} difunto={difuntoSeleccionado} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ContratoList;
