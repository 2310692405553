import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';

const Columbarios = ({ agregarAlPresupuesto }) => {
  const [capilla, setCapilla] = useState('');
  const [lado, setLado] = useState('');
  const [cantidadNichos, setCantidadNichos] = useState('');
  const [fila, setFila] = useState('');
  const [columna, setColumna] = useState('');
  const [codigoNicho, setCodigoNicho] = useState(''); 
  const [planSeleccionado, setPlanSeleccionado] = useState(null);

  const [modalidadPago, setModalidadPago] = useState('');
  const [inicial, setInicial] = useState(0);
  const [montoFinanciar, setMontoFinanciar] = useState(0);
  const [cuotas, setCuotas] = useState(1);
  const [montoCuotas, setMontoCuotas] = useState(0);

  const planes = [
    { id: 1, item: 'Plan-01', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE EXTERNA- FILAS ALTAS)', tipo_servicio: 'Nicho de Columbario', precioFinanciado: 400, inicial: 80, cuotas: 8, precio_cuotas: 40, precioContado: 300 },
    { id: 2, item: 'Plan-02', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE EXTERNA- FILAS MEDIAS)', tipo_servicio: 'Nicho de Columbario', precioFinanciado: 500, inicial: 100, cuotas: 8, precio_cuotas: 50, precioContado: 400 },
    { id: 3, item: 'Plan-03', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE INTERNA- FILAS ALTAS)', tipo_servicio: 'Nicho de Columbario', precioFinanciado: 600, inicial: 120, cuotas: 8, precio_cuotas: 60, precioContado: 500 },
    { id: 4, item: 'Plan-04', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE INTERNA- FILAS MEDIAS)', tipo_servicio: 'Nicho de Columbario', precioFinanciado: 700, inicial: 140, cuotas: 8, precio_cuotas: 70, precioContado: 600 },
  ];

  const handlePlanChange = (e) => {
    const planId = parseInt(e.target.value, 10);
    const plan = planes.find((p) => p.id === planId);
    setPlanSeleccionado(plan);
    // Reset al cambiar plan
    setInicial(0);
    setCuotas(1);
  };

  useEffect(() => {
    if (capilla && lado && cantidadNichos && fila && columna) {
      const codigo = `${capilla}-${lado}${cantidadNichos}-${fila}${columna}`;
      setCodigoNicho(codigo);
    }
  }, [capilla, lado, cantidadNichos, fila, columna]);

  useEffect(() => {
    if (!planSeleccionado) {
      setMontoFinanciar(0);
      setMontoCuotas(0);
      return;
    }

    if (modalidadPago === 'financiado') {
      const total = planSeleccionado.precioFinanciado;
      const montoFinanciarCalculado = total - inicial;
      setMontoFinanciar(montoFinanciarCalculado > 0 ? montoFinanciarCalculado : 0);

      const montoCuotaCalculado = (montoFinanciarCalculado > 0 && cuotas > 0)
        ? (montoFinanciarCalculado / cuotas)
        : 0;
      setMontoCuotas(montoCuotaCalculado > 0 ? montoCuotaCalculado : 0);
    } else if (modalidadPago === 'contado') {
      // Contado
      setMontoFinanciar(0);
      setMontoCuotas(0);
    } else {
      // Sin modalidad
      setMontoFinanciar(0);
      setMontoCuotas(0);
    }
  }, [planSeleccionado, modalidadPago, inicial, cuotas]);

  const handleAgregar = () => {
    if (planSeleccionado && modalidadPago !== '') {
      let precioTotal = 0;
      let modalidad = '';

      if (modalidadPago === 'financiado') {
        precioTotal = planSeleccionado.precioFinanciado;
        modalidad = 'Financiado';
      } else if (modalidadPago === 'contado') {
        precioTotal = planSeleccionado.precioContado;
        modalidad = 'De Contado';
      } else {
        return;
      }

      const item = {
        codigo: codigoNicho,
        descripcion: `${planSeleccionado.item} - ${planSeleccionado.servicio}`,
        tipo_servicio: planSeleccionado.tipo_servicio,
        modalidadPago: modalidad,
        precioTotal: precioTotal,
        pagoInicial: (modalidadPago === 'financiado') ? inicial : '',
        cuotas: (modalidadPago === 'financiado') ? cuotas : '',
        montoPorCuota: (modalidadPago === 'financiado') ? montoCuotas : '',
        precioFinanciado: (modalidadPago === 'financiado') ? planSeleccionado.precioFinanciado : '',
        precioContado: (modalidadPago === 'contado') ? planSeleccionado.precioContado : '',
      };
      
      agregarAlPresupuesto(item);           
    }
  };

  return (
    <>
      <Typography sx={{ marginTop: '25px', marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }} variant="h5">
        CONTRATO NICHOS DE COLUMBARIOS
      </Typography>
      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          PLAN A CONTRATAR
        </Typography>
      </Box>

      {/* Ubicación */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Capilla</InputLabel>
            <Select value={capilla} label="Capilla" onChange={(e) => setCapilla(e.target.value)}>
              <MenuItem value="CP1">Capilla Principal (CP1)</MenuItem>
              <MenuItem value="CC1">Capilla de Contemplación 1 (CC1)</MenuItem>
              <MenuItem value="CC2">Capilla de Contemplación 2 (CC2)</MenuItem>
              <MenuItem value="CC3">Capilla de Contemplación 3 (CC3)</MenuItem>
              <MenuItem value="CC4">Capilla de Contemplación 4 (CC4)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Lado</InputLabel>
            <Select value={lado} label="Lado" onChange={(e) => setLado(e.target.value)}>
              {['A', 'B', 'C', 'D'].map((lado, index) => (
                <MenuItem key={index} value={lado}>
                  {lado}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Cantidad de Nichos</InputLabel>
            <Select value={cantidadNichos} label="Cantidad de Nichos" onChange={(e) => setCantidadNichos(e.target.value)}>
              <MenuItem value="1">Uno (1) Nicho</MenuItem>
              <MenuItem value="2">Dos (2) Nichos</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Fila</InputLabel>
            <Select value={fila} label="Fila" onChange={(e) => setFila(e.target.value)}>
              {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'].map((f, index) => (
                <MenuItem key={index} value={f}>
                  {f}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Columna</InputLabel>
            <Select value={columna} label="Columna" onChange={(e) => setColumna(e.target.value)}>
              {Array.from({ length: 51 }, (_, i) => i + 1).map((col, index) => (
                <MenuItem key={index} value={col}>
                  {col < 10 ? `0${col}` : col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Primera línea: 
          - Contado: Plan(4) - Modalidad(2) - Precio Contado(3) - Botón(3)
          - Financiado: Plan(5) - Modalidad(2) - Precio Financiado(3) - Monto Inicial(2)
      */}
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        {/* Plan */}
        <Grid item xs={planSeleccionado && modalidadPago === 'financiado' ? 5 : 4}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel id="planParcela-label">Plan</InputLabel>
            <Select
              labelId="planParcela-label"
              id="planParcela"
              label="Plan"
              value={planSeleccionado ? planSeleccionado.id : ''}
              onChange={handlePlanChange}
              autoComplete="off"
            >
              <MenuItem value="" disabled>
                Selecciona un Plan
              </MenuItem>
              {planes.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.servicio} - {plan.item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Modalidad */}
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
            <InputLabel id="modalidad">Modalidad de Pago</InputLabel>
            <Select
              labelId="modalidad de Pago"
              name="modalidad"
              value={modalidadPago}
              onChange={(e) => setModalidadPago(e.target.value)}
              label="Modalidad de Pago"
            >
              <MenuItem value="" disabled>Seleccione una opción</MenuItem>
              <MenuItem value="contado">De Contado</MenuItem>
              <MenuItem value="financiado">Financiado</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {planSeleccionado && modalidadPago === 'contado' && (
          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Precio al Contado"
                variant="outlined"
                size="small"
                name="pago_contado"
                value={planSeleccionado.precioContado !== '' ? `$${planSeleccionado.precioContado}` : ''}
                sx={{ boxShadow: 3 }}
                InputProps={{ readOnly: true }}
              />
            </Grid>        
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAgregar}
                disabled={!planSeleccionado || modalidadPago === ''}
                fullWidth
              >
                Agregar al Contrato
              </Button>
            </Grid>
          </>
        )}
        
        {planSeleccionado && modalidadPago === 'financiado' && (
          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Precio Financiado"
                variant="outlined"
                size="small"
                name="pago_financiado"
                value={planSeleccionado.precioFinanciado !== '' ? `$${planSeleccionado.precioFinanciado}` : ''}
                sx={{ boxShadow: 3 }}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Monto Inicial ($)"
                variant="outlined"
                size="small"
                type="number"
                name="inicial"
                value={inicial}
                onChange={(e) => setInicial(parseFloat(e.target.value) || 0)}
                sx={{ boxShadow: 3 }}              
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* Segunda línea para financiado:
          Monto a Financiar(3) - Cuotas(3) - Monto por Cuota(3) - Botón Agregar(3)
      */}
      {planSeleccionado && modalidadPago === 'financiado' && (
        <Grid container spacing={2} sx={{ marginTop: '10px' }}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Monto a Financiar ($)"
              variant="outlined"
              size="small"
              name='monto_financiar'
              value={`$${montoFinanciar.toFixed(2)}`} // Solo lectura
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Cuotas"
              variant="outlined"
              size="small"
              name="cuotas"
              value={cuotas}
              onChange={(e) => setCuotas(parseInt(e.target.value) || 1)}
              sx={{ boxShadow: 3 }}              
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Monto por Cuota"
              variant="outlined"
              size="small"
              name="monto_cuotas"
              value={`$${montoCuotas.toFixed(2)}`}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAgregar}
              disabled={!planSeleccionado || modalidadPago === ''}
              fullWidth
            >
              Agregar al Contrato
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Columbarios;